import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from '@chakra-ui/react';
import RouterDefault from 'next/router';
import { Box } from '../Box';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { HStack } from '../HStack';
import { Logo, LogoAndMark } from '../Icon';
import { Input } from '../Input';
import { ButtonLink, RouteLink } from '../Link';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '../Popover';
import { Stack } from '../Stack';
import { Switch } from '../Switch';
import { Text } from '../Text';
const DRAFT_MODE_PROPS = {
    bg: 'red.900',
    _after: {
        content: '"Draft Mode"',
        position: 'absolute',
        top: 0,
        left: '50%',
        fontSize: '28px',
        lineHeight: '56px',
        opacity: 0.2,
        fontWeight: 'bold',
        marginLeft: '-75px',
        pointerEvents: 'none',
    },
};
export const AppBar = forwardRef((props, ref) => {
    const { children, ...appBarProps } = props;
    const isDraft = RouterDefault.isPreview;
    const draftModeProps = isDraft ? DRAFT_MODE_PROPS : {};
    return (_jsx(HStack, { ref: ref, color: 'white', bg: 'gray.800', alignItems: 'center', display: 'flex', px: { base: 2, md: 4 }, minH: 12, spacing: { base: 2, md: 3 }, position: 'relative', ...appBarProps, ...draftModeProps, children: children }));
});
export const AppBarSection = forwardRef((props, ref) => {
    const { children, ...appBarSectionProps } = props;
    return (_jsx(HStack, { ref: ref, position: 'relative', spacing: 1, ...appBarSectionProps, children: children }));
});
export function AppBarLogo({ showMark, ...props }) {
    return showMark ? _jsx(LogoAndMark, { ...props }) : _jsx(Logo, { ...props });
}
AppBarLogo.displayName = 'AppBarLogo';
export const AppBarNavigationItem = forwardRef(({ href, isActive, ...props }, ref) => {
    return (_jsx(ButtonLink, { as: RouteLink, ref: ref, href: href, colorScheme: 'gray', mx: { base: 0, lg: 1 }, variant: 'ghost', size: 'sm', borderRadius: 'full', isActive: isActive, color: 'gray.100', shallow: true, ...props }));
});
AppBarNavigationItem.displayName = 'AppBarNavigationItem';
export function AppBarShare() {
    return (_jsx(Box, { children: _jsxs(Popover, { placement: 'bottom-end', children: [_jsx(PopoverTrigger, { children: _jsx(Button, { variant: 'solid', colorScheme: 'blue', children: 'Share' }) }), _jsxs(PopoverContent, { color: 'gray.800', w: '25rem', children: [_jsx(PopoverArrow, {}), _jsx(PopoverBody, { px: 0, children: _jsxs(Stack, { spacing: 4, children: [_jsxs(HStack, { px: 6, children: [_jsxs(Stack, { spacing: 1, flex: 1, children: [_jsx(Heading, { children: 'Share to the web' }), _jsx(Text, { fontSize: 'sm', color: 'gray.500', children: 'Publish and share a link with anyone' })] }), _jsx(Switch, {})] }), _jsxs(HStack, { spacing: 2, px: 6, children: [_jsx(Input, { value: 'Something', flex: '1', readOnly: true }), _jsx(Button, { flex: 'none', children: 'Copy' })] }), _jsx(Divider, {}), _jsxs(Stack, { spacing: 3, children: [_jsxs(HStack, { px: 6, children: [_jsxs(Stack, { spacing: 1, flex: 1, children: [_jsx(Heading, { children: 'Allow editing' }), _jsx(Text, { fontSize: 'sm', color: 'gray.500', children: "Lets anyone to edit the canvas" })] }), _jsx(Switch, {})] }), _jsxs(HStack, { px: 6, children: [_jsxs(Stack, { spacing: 1, flex: 1, children: [_jsx(Heading, { children: 'Share on hatch' }), _jsx(Text, { fontSize: 'sm', color: 'gray.500', children: 'Visible for all Hatch users to see for inspiration. Also shared on your public profile page' })] }), _jsx(Switch, {})] })] })] }) })] })] }) }));
}
AppBarShare.displayName = 'AppBarShare';
