import { Box2d } from '@playful/primitives';
// Join paths with a /
export function pathJoin(parts) {
    const ret = parts
        .map((part, i) => {
        if (i === 0) {
            return part.trim().replace(/[\/]*$/g, '');
        }
        else {
            return part.trim().replace(/(^[\/]*|[\/]*$)/g, '');
        }
    })
        .filter((x) => x.length)
        .join('/');
    // Fixup for when all parts end up being empty
    if (parts.length > 0 && ret === '') {
        return '/';
    }
    return ret;
}
// Return the <dir> part of <dir>/<file>.<ext>
export function dirName(path) {
    return path.substring(0, path.lastIndexOf('/'));
}
// foo.ext -> foo, foo.bar.ext -> foo.bar
export function trimFileExtension(s) {
    return s.replace(/\.[^/.]+$/, '');
}
// Convert the string into a valid Javascript identifier by replacing invalid chars
// with underscores and prepending an underscore if it starts with a digit.
export function stringToIdentifier(s) {
    s = s.replace(/\W/g, '_');
    if (s[0] >= '0' && s[0] <= '9') {
        s = '_' + s;
    }
    return s;
}
export function getShadowDomAwareTarget(event) {
    if (event.composedPath) {
        return event.composedPath()[0];
    }
    let ret = event.target;
    while (ret?.shadowRoot != null) {
        ret = ret.shadowRoot.activeElement;
    }
    return ret;
}
export function dumpPrototypeChain(p) {
    const names = [];
    while (p) {
        names.push(p.hasOwnProperty('iam') ? p.iam : 'unknown');
        p = Reflect.getPrototypeOf(p);
    }
    console.log(names.join(' -> '));
}
export function appendSearchParam(url, param, value) {
    const parsed = typeof document !== 'undefined' ? new URL(url, document.baseURI) : new URL(url);
    parsed.searchParams.append(param, value);
    return parsed.toString();
}
export function getEditUrl(info) {
    const editUrl = new URL(window.location.href);
    editUrl.pathname = `@${info?.ownerName}/${info?.slug}`;
    return editUrl.href;
}
export function BoxFromView(view) {
    return new Box2d(view.x || 0, view.y || 0, view.width || 0, view.height || 0);
}
export const migrateTextToHtml = (text) => `<p>${(text || '').replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>`;
export function pointsEqual(a, b) {
    return Boolean(a && b && a.x === b.x && a.y === b.y);
}
