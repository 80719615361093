import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "pendingDomainObject", "currentDomainIsInvalid", "onCancel", "isUrlDirty", "isUpdatingUrl", "isSwitchingToCustomDomain", "onSubmitNewUrl", "CustomThumbnailInput"],
  _excluded2 = ["children"],
  _excluded3 = ["children"];
var __jsx = React.createElement;
import { Box, Collapse, FormControl, forwardRef, useDisclosure } from '@chakra-ui/react';
import { ChevronUpIcon, FormLabel, Text } from '@playful/design_system';
import React from 'react';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { useUserContext } from '../user/UserContext';
import { ChooseDomainAndPath } from './ChooseDomainAndPath';
import { ChooseSlug } from './ChooseSlug';
import { CurrentUrlDisplay } from './CurrentUrlDisplay';
import { DomainStatusBlock } from './DomainStatusBlock';
import { ProjectCustomThumbnail } from './ProjectCustomThumbnail';
import { ProjectPermissions } from './ProjectPermissions';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsCTAs } from './ProjectSettingsCTAs';
import { ProjectSettingsForm } from './ProjectSettingsForm';
import { ProjectSharingOptions } from './ProjectSharingOptions';
import { PublishButton } from './PublishButton';
import { SettingTitle } from './SettingsTitle';
export var ProjectSettingsLayout = forwardRef(function (props, ref) {
  var _useDisclosure = useDisclosure(),
    isMoreSettingsOpen = _useDisclosure.isOpen,
    toggleMoreSettings = _useDisclosure.onToggle;
  var children = props.children,
    pendingDomainObject = props.pendingDomainObject,
    currentDomainIsInvalid = props.currentDomainIsInvalid,
    onCancel = props.onCancel,
    isUrlDirty = props.isUrlDirty,
    isUpdatingUrl = props.isUpdatingUrl,
    isSwitchingToCustomDomain = props.isSwitchingToCustomDomain,
    onSubmitNewUrl = props.onSubmitNewUrl,
    CustomThumbnailInput = props.CustomThumbnailInput,
    boxProps = _objectWithoutProperties(props, _excluded);
  var _useUserContext = useUserContext(),
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isPublished = _useProjectSettingsCo.isPublished,
    isUpdatedDomainCustom = _useProjectSettingsCo.isUpdatedDomainCustom,
    canPublish = _useProjectSettingsCo.canPublish,
    isEditingPublicUrl = _useProjectSettingsCo.isEditingPublicUrl,
    setIsEditingPublicUrl = _useProjectSettingsCo.setIsEditingPublicUrl;
  return __jsx(Box, _extends({
    ref: ref
  }, boxProps, {
    px: 6,
    pb: 6,
    pt: 4,
    height: '100%',
    borderRadius: 'lg',
    maxHeight: '100%',
    overflowY: 'auto'
  }), __jsx(SettingTitle, null, isPublished ? 'published!' : 'publish to the web'), isPublished && isUpdatedDomainCustom && pendingDomainObject && __jsx(DomainStatusBlock, {
    domain: pendingDomainObject
  }), (!isPublished || isEditingPublicUrl) && __jsx(React.Fragment, null, !hasActiveSubscription && __jsx(ChooseSlug, null), hasActiveSubscription && __jsx(ChooseDomainAndPath, null), isPublished && isEditingPublicUrl && __jsx(ProjectSettingsBox, {
    color: 'gray.700',
    borderTop: 'none',
    paddingTop: 0,
    flexDirection: 'column'
  }, __jsx(ProjectSettingsForm, null))), isPublished && !isEditingPublicUrl && __jsx(CurrentUrlDisplay, {
    onEdit: function onEdit() {
      setIsEditingPublicUrl(true);
    }
  }), isPublished && isEditingPublicUrl && __jsx(React.Fragment, null, __jsx(Box, {
    display: 'flex',
    pt: 0,
    mb: 3,
    gap: 4,
    justifyContent: 'flex-end'
  }, __jsx(CTAButton, {
    colorScheme: "gray",
    onClick: onCancel,
    isDisabled: currentDomainIsInvalid
  }, "cancel"), __jsx(CTAButton, {
    onClick: onSubmitNewUrl,
    flexGrow: 1,
    isLoading: isUpdatingUrl,
    isDisabled: !isUrlDirty || !canPublish,
    title: isUrlDirty ? 'publish the new url' : 'no changes to publish'
  }, "update")), !isSwitchingToCustomDomain ? __jsx(Text, {
    size: 'xs',
    color: 'gray.500',
    mb: 6
  }, "Old links won't work after you update a URL") : __jsx(Box, {
    mb: 3
  })), isPublished && !isEditingPublicUrl && __jsx(ProjectSharingOptions, null), isPublished && !isEditingPublicUrl && __jsx(ProjectCustomThumbnail, null), isPublished && !isEditingPublicUrl && __jsx(Box, {
    borderTop: '1px solid var(--play-colors-gray-50)',
    mx: -6,
    px: 6
  }, __jsx(SettingTitle, {
    mt: 4
  }, "settings"), __jsx(Box, null, __jsx(FormControl, {
    py: 1
  }, __jsx(SearchEngineIndexing, null)), __jsx(ProjectPermissions, null))), !isPublished && __jsx(FormControl, {
    mb: 4
  }, __jsx(SearchEngineIndexing, null)), !isPublished && __jsx(Box, {
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    onClick: toggleMoreSettings,
    display: 'flex',
    color: 'gray.600',
    fontSize: '14px',
    fontWeight: 500
  }, __jsx(Text, null, "more settings"), __jsx(ChevronUpIcon, {
    transform: "rotate(".concat(isMoreSettingsOpen ? 180 : 0, "deg)"),
    transition: 'transform 0.3s ease-in-out',
    w: 4,
    h: 4
  })), !isPublished && __jsx(Collapse, {
    in: isMoreSettingsOpen
  }, __jsx(ProjectSettingsBox, {
    color: 'gray.500',
    borderTop: 'none',
    flexDirection: 'column'
  }, __jsx(ProjectSettingsForm, null))), !isEditingPublicUrl && __jsx(PublishButton, {
    isLoading: isUpdatingUrl
  }), __jsx(ProjectSettingsCTAs, null));
});
export var ProjectSettingsBox = function ProjectSettingsBox(_ref) {
  var children = _ref.children,
    boxProps = _objectWithoutProperties(_ref, _excluded2);
  return __jsx(Box, _extends({
    borderTop: '1px solid var(--play-colors-gray-50)',
    mx: -6,
    px: 6,
    py: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }, boxProps), children);
};
export var ProjectSettingsFormLabel = function ProjectSettingsFormLabel(_ref2) {
  var children = _ref2.children,
    props = _objectWithoutProperties(_ref2, _excluded3);
  return __jsx(FormLabel, _extends({
    fontSize: 'sm',
    fontWeight: "normal",
    color: 'gray.500'
  }, props), children);
};
function SearchEngineIndexing() {
  return null;
  /*
  const { projectInfo, onPageMetadataInputChange, pageMetadataInput } = useProjectSettingsContext();
  const { updateProjectMetadata, pageMetadata } = useProject({ id: projectInfo.id });
   // back-sync searchEngineIndex so we don't lose it on publish change where we use pageMetadataInput
  useEffect(() => {
    if (isNil(pageMetadata?.searchEngineIndex)) return;
     onPageMetadataInputChange((pageMetadataInput) => ({
      ...pageMetadataInput,
      searchEngineIndex: pageMetadata?.searchEngineIndex,
    }));
  }, [pageMetadata?.searchEngineIndex, onPageMetadataInputChange]);
   return (
    <SwitchField>
      <Box>
        <SwitchLabel
          m={0}
          htmlFor='no-index'
          tooltipLabel={'Allow search engines to index your site so it appears in search results'}
        >
          make site visible to search engines
        </SwitchLabel>
      </Box>
      <Switch
        id='no-index'
        size='md'
        name={'searchEngineIndex'}
        onChange={(e) =>
          updateProjectMetadata({
            pageMetadata: { ...pageMetadata, searchEngineIndex: e.target.checked },
          })
        }
        isChecked={pageMetadataInput?.searchEngineIndex}
      />
    </SwitchField>
  );
  */
}